<template>
  <data-table
    :url="`/tickets/timelines?ticket_id=${ticketId}`"
    :columns="cols"
  />
</template>

<script>
import DataTable from '../../CustomComponents/DataTable/DataTable.vue'
import TicketTimeLineCol from './TicketTimeLineCol.vue'

const cols = [
  {
    key: '',
    disabled: true,
    header: {
      name: ' ',
    },
    component: TicketTimeLineCol,
  },
]

export default {
  components: {
    DataTable,
  },
  data: () => ({
    cols,
  }),
  computed: {
    ticketId() { return this.$route.params.id },
  },
}
</script>

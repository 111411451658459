<template>
  <div style="line-height: 24px;">
    <span class="text-secondary"><feather-icon style="margin-top: -2px;" icon="CalendarIcon" /> {{ date }}</span> ~ <strong><feather-icon icon="UserIcon" /> "{{ fullName }}"</strong> moved this ticket to the column <strong>"{{ columnName }}" <feather-icon icon="ColumnsIcon" /></strong>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'TicketTimelineCol',
  props: {
    col: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fullName() {
      return `${this.data?.user?.user_firstname ?? ''}${this.data?.user?.user_lastname ? ' ' : ''}${this.data?.user?.user_lastname ?? ''}`
    },
    date() {
      return moment(this.data?.ticket_move_time_in).format('l - HH:mm:ss a')
    },
    columnName() {
      return this.data?.column?.column_name ?? ''
    },
  },
  methods: {
    moment,
  },
}
</script>
